<template>
  <div class="realName">
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane
        v-for="(tab, tabIndex) in tabs"
        :key="tabIndex"
        :label="tab.label"
        :name="tab.name"
      >
        <router-view />
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
export default {
  name: "RealName",
  data() {
    return {
      activeName: "personal",
      tabs: [
        {
          name: "personal",
          path: "/copyrightService/realName/personalRealName",
          label: "个人实名认证",
        },
        {
          name: "organization",
          path: "/copyrightService/realName/organizationRealName",
          label: "机构实名认证",
        },
      ],
    };
  },
  methods: {
    handleClick(tab) {
      this.tabs.forEach((item, index) => {
        if (tab.index == index && !this.$store.state.realNameData) {
          this.$router.push(item.path);
        }
      });
    },
  },
  mounted() {
    let realName = this.$store.state.realNameData;
    if (realName !== null) {
      let name = "";
      let params =  {
        realName: realName.cardName,
      }
      if (realName.realnameType == 1) {
        name = "PersonalAlreadyRealName";
      } else {
        name = "OrganizationAlreadyRealName";
      }
      if(realName.examine){
        name = "OrganizationAlreadyRealName";
        params.examine = realName.examine;
      }
      this.$router.push({
        name,
        params
      });
    } else {
      this.tabs.forEach((tab) => {
        if (tab.name == this.activeName && !realName) {
          this.$router.push(tab.path);
        }
      });
    }
  },
};
</script>

<style lang="scss" scoped>
.realName {
  height: 800px;
  ::v-deep .el-tabs {
    .el-tabs__header {
      margin: 0;
    }
    .el-tabs__nav-wrap {
      &::after {
        display: none;
      }
    }
    .el-tabs__nav-scroll {
      .el-tabs__active-bar {
        display: none;
      }
      .el-tabs__nav {
        @include flex-start;
        padding: 22px 0 22px 40px;
        .el-tabs__item {
          line-height: normal;
          height: 100%;
          padding: 0;
          font-size: 12px;
          font-weight: 500;
          color: #999999;
          &.is-active {
            color: #4587ff;
          }
        }
        .el-tabs__item:nth-child(2) {
          margin-right: 40px;
        }
      }
    }
  }
}
</style>
